<template>
  <v-container fluid grid-list-md>
    <v-layout justify-center>
      <v-flex md8>
        <v-toolbar flat dense
          ><v-toolbar-title style="font-size: 1.8em"> Loja </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap align-center>
          <v-flex v-for="(item, i) in items" :key="i">
            <v-card
              :style="'border: 1px solid LightGrey'"
              :to="item.to"
              class="mx-auto"
              max-width="300"
              max-height="100"
              hover
              v-if="mixPermissao($options.name, item.name)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>
                  <v-card-subtitle
                    class="subheading grey--text text--darken-1"
                    v-text="item.subtitle"
                  ></v-card-subtitle>
                </div>

                <v-icon class="mr-4" x-large :color="item.icolor">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PermissoesMixin from "@/mixins/PermissoesMixin";

export default {
  name: "Loja",
  mixins: [PermissoesMixin],
  data: () => ({
    items: [
      {
        to: "/LojaClientesLista",
        icon: "mdi-account-outline",
        icolor: "yellow darken-4",
        title: "Clientes",
        subtitle: "Clientes compram na loja",
        name: "clientes",
      },

      {
        to: "/lojaConveniadasLista",
        icon: "mdi-account-group-outline",
        icolor: "blue darken-2",
        title: "Conveniadas",
        subtitle: "Empresas com convênio",
        name: "conveniadas",
      },
      {
        to: "/lojaEmpresasLista",
        icon: "mdi-domain",
        icolor: "pink darken-2",
        title: "Empresas",
        subtitle: "Cadastro de empresas",
        name: "empresas",
      },
      {
        to: "/lojaCrediariosLista",
        icon: "mdi-timer-sand",
        icolor: "green darken-2",
        title: "Crediário",
        subtitle: "Vendas no crediário",
        name: "crediario",
      },
      {
        to: "/lojaEstoque",
        icon: "mdi-package-variant",
        icolor: "blue darken-2",
        title: "Estoque",
        subtitle: "Controle do estoque",
        name: "estoque",
      },
      {
        to: "/lojaRelatorios",
        icon: "mdi-printer",
        icolor: "green darken-4",
        title: "Relatorios",
        subtitle: "Relatorios gerais",
        name: "relatorios",
      },
      {
        to: "/lojaFinanceiro",
        icon: "mdi-cash-check",
        icolor: "red darken-3",
        title: "Financeiro",
        subtitle: "Controle financeiro",
        name: "financeiro",
      },
      {
        to: "/lojaConsultas",
        icon: "mdi-search-web",
        icolor: "yellow darken-4",
        title: "Consultas",
        subtitle: "Consulta Serasa e SPC",
        name: "consultas",
      },
    ],
  }),
};
</script>
